const HttpBackend = require('i18next-http-backend/cjs')
const ChainedBackend = require('i18next-chained-backend').default
const LocalStorageBackend = require('i18next-localstorage-backend').default
const path = require('path')

module.exports = {
  backend: {
    backendOptions: [{expirationTime: 60 * 60 * 1000}, { /* loadPath: 'https:// somewhere else' */}], // 1 hour
    backends: typeof window !== 'undefined' ? [LocalStorageBackend, HttpBackend] : [],
  },
  // debug: true,
  serializeConfig: false,
  use: typeof window !== 'undefined' ? [ChainedBackend] : [],
  i18n: {
    defaultLocale: 'en',
    locales: ['en'],
  },
  localePath: path.resolve('./public/locales'),
  reloadOnPrerender: process.env.NODE_ENV === 'development',
};

import Dropdown from "../ui/dropdowns/Dropdown";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { signOut, useSession } from "next-auth/react";

export function signOutUser() {
  signOut({
    callbackUrl: `${process.env.NEXT_PUBLIC_BASE_URL}/api/auth/logout`,
  });
}

export default function UserProfileMenu() {
  const { data: session } = useSession();

  return (
    <Dropdown
      openAbove={true}
      className={
        "focus:ring-0 focus:ring-offset-0 rounded-none border-0 border-t"
      }
      menuButton={
        <span className="flex w-full justify-between items-center">
          <span className="flex min-w-0 items-center justify-between space-x-3">
            <img
              className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
              src={session?.user.image}
              alt=""
            />
            <span className="flex-1 flex flex-col min-w-0">
              <span className="text-gray-500 text-sm truncate">
                {session?.user.email}
              </span>
            </span>
          </span>
          <ChevronUpDownIcon
            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </span>
      }
      menuItems={[{ label: "Sign out", onClick: signOutUser }]}
    />

    // <Menu
    //   as="div"
    //   className="border-t border-gray-200 px-3 relative inline-block text-left"
    // >
    //   <div>
    //     <Menu.Button className="group w-full  rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
    //       <span className="flex w-full justify-between items-center">
    //         <span className="flex min-w-0 items-center justify-between space-x-3">
    //           <img
    //             className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
    //             src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
    //             alt=""
    //           />
    //           <span className="flex-1 flex flex-col min-w-0">
    //             <span className="text-gray-900 text-sm font-medium truncate">
    //               Jessy Schwarz
    //             </span>
    //             <span className="text-gray-500 text-sm truncate">
    //               @jessyschwarz
    //             </span>
    //           </span>
    //         </span>
    //         <ChevronUpDownIcon
    //           className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
    //           aria-hidden="true"
    //         />
    //       </span>
    //     </Menu.Button>
    //   </div>
    //   <Transition
    //     as={Fragment}
    //     enter="transition ease-out duration-100"
    //     enterFrom="transform opacity-0 scale-95"
    //     enterTo="transform opacity-100 scale-100"
    //     leave="transition ease-in duration-75"
    //     leaveFrom="transform opacity-100 scale-100"
    //     leaveTo="transform opacity-0 scale-95"
    //   >
    //     <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
    //       <div className="py-1">
    //         <Menu.Item>
    //           {({ active }) => (
    //             <a
    //               href="#"
    //               className={classNames(
    //                 active ? "bg-gray-100 text-gray-900" : "text-gray-700",
    //                 "block px-4 py-2 text-sm"
    //               )}
    //             >
    //               View profile
    //             </a>
    //           )}
    //         </Menu.Item>
    //         <Menu.Item>
    //           {({ active }) => (
    //             <a
    //               href="#"
    //               className={classNames(
    //                 active ? "bg-gray-100 text-gray-900" : "text-gray-700",
    //                 "block px-4 py-2 text-sm"
    //               )}
    //             >
    //               Settings
    //             </a>
    //           )}
    //         </Menu.Item>
    //         <Menu.Item>
    //           {({ active }) => (
    //             <a
    //               href="#"
    //               className={classNames(
    //                 active ? "bg-gray-100 text-gray-900" : "text-gray-700",
    //                 "block px-4 py-2 text-sm"
    //               )}
    //             >
    //               Notifications
    //             </a>
    //           )}
    //         </Menu.Item>
    //       </div>
    //       <div className="py-1">
    //         <Menu.Item>
    //           {({ active }) => (
    //             <a
    //               href="#"
    //               className={classNames(
    //                 active ? "bg-gray-100 text-gray-900" : "text-gray-700",
    //                 "block px-4 py-2 text-sm"
    //               )}
    //             >
    //               Get desktop app
    //             </a>
    //           )}
    //         </Menu.Item>
    //         <Menu.Item>
    //           {({ active }) => (
    //             <a
    //               href="#"
    //               className={classNames(
    //                 active ? "bg-gray-100 text-gray-900" : "text-gray-700",
    //                 "block px-4 py-2 text-sm"
    //               )}
    //             >
    //               Support
    //             </a>
    //           )}
    //         </Menu.Item>
    //       </div>
    //       <div className="py-1">
    //         <Menu.Item>
    //           {({ active }) => (
    //             <a
    //               href="#"
    //               className={classNames(
    //                 active ? "bg-gray-100 text-gray-900" : "text-gray-700",
    //                 "block px-4 py-2 text-sm"
    //               )}
    //             >
    //               Logout
    //             </a>
    //           )}
    //         </Menu.Item>
    //       </div>
    //     </Menu.Items>
    //   </Transition>
    // </Menu>
  );
}

import { Icon as Iconify } from "@iconify/react";
import React from "react";
import { twMerge } from "tailwind-merge";

export default function Icon({ icon, width = 24, height = 24, ...other }) {
  return <Iconify icon={icon} width={width} height={height} {...other} />;
}

export function LoadingIcon({ className }) {
  return (
    <Iconify
      icon={"gg:spinner"}
      className={twMerge("animate-spin", className)}
    />
  );
}

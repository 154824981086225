import axios from "axios";

const axiosService = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
});
export const setAuthToken = (token) => {
  if (!!token) {
    axiosService.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosService.defaults.headers.common["Authorization"];
  }
};

export default axiosService;

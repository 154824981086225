import "../styles/globals.css";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useState } from "react";
import { appWithTranslation } from "next-i18next";
import nextI18nConfig from "../next-i18next.config";
import { SessionProvider, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useAccount } from "../backend/account/useAccount";
import { setAuthToken } from "../services/axiosService";
import { LoadingScreen } from "../components/ui/loading/LoadingScreen";
import { signOutUser } from "../components/user/UserProfileMenu";

function doesPathRequireLogin(pathname) {
  return (
    !pathname.startsWith("/api") &&
    !pathname.startsWith("/auth") &&
    !pathname.startsWith("/[eventSlug]") &&
    !pathname.endsWith("create-account")
  );
}

function ValidateAuth({ children }) {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status, data: session } = useSession({ required: true });

  if (status === "loading") {
    return <></>;
  }

  if (status === "authenticated") {
    setAuthToken(session.accessToken);
  } else if (status === "unauthenticated") {
    setAuthToken("");
  }

  return children;
}

function Auth({ children }) {
  const { pathname } = useRouter();

  if (!doesPathRequireLogin(pathname)) {
    return children;
  }

  return <ValidateAuth>{children}</ValidateAuth>;
}

function ValidateOnboarding({ children }) {
  const { isLoading, data: account, error } = useAccount();
  const router = useRouter();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error && error.response?.status === 401) {
    signOutUser();
  }

  if (account && account.selected_platform !== "zoom") {
    router.push("/signup/platform");
    return <></>;
  }

  if (account && !account.platform_connected) {
    router.push("/signup/zoom");
    return <></>;
  }

  return children;
}

function Onboarding({ children }) {
  const { pathname } = useRouter();

  if (!doesPathRequireLogin(pathname) || pathname.startsWith("/signup")) {
    return children;
  }

  return <ValidateOnboarding>{children}</ValidateOnboarding>;
}

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <SessionProvider session={session}>
          <Auth>
            <Onboarding>
              <Component {...pageProps} />
            </Onboarding>
          </Auth>
        </SessionProvider>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default appWithTranslation(MyApp, nextI18nConfig);

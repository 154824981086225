import axiosService from "../../services/axiosService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAuthorizationHeaderIfToken } from "../../utils/authToken";

export const QUERY_ACCOUNT = "account";

export const fetchAccount = async (accessToken) => {
  const results = await axiosService.get(
    "/api/account",
    getAuthorizationHeaderIfToken(accessToken)
  );
  return results.data;
};

export const useAccount = () => {
  return useQuery([QUERY_ACCOUNT], () => fetchAccount());
};

export const updateAccount = async (account) => {
  const results = await axiosService.patch(
    `/api/accounts/${account.id}/`,
    account
  );
  return results.data;
};

export const useAccountMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((updatedAccount) => updateAccount(updatedAccount), {
    onSuccess: (variables) => {
      queryClient.invalidateQueries([QUERY_ACCOUNT]);
    },
  });
};
